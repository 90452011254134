import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Button.scss';

const Button = ({
  color,
  size,
  className,
  to,
  onClick,
  form,
  type,
  children,
  disabled,
}) => {
  const classes = [styles.btn, styles[color], styles[size], className]
    .join(' ')
    .trim();

  const Wrapper = to ? Link : 'button';

  return (
    <Wrapper
      className={classes}
      to={to}
      onClick={onClick}
      form={form}
      type={type}
      disabled={disabled}
    >
      {children}
    </Wrapper>
  );
};

export default Button;
