import React from 'react';
import { Link } from 'react-router-dom';
import locals from './ServerBanner.scss';

const ServerBanner = ({
  serverId,
  className = null,
  src = 'e426ea9b4865c509086ff3759b0df835121f507cff9e12ad586b16ff582de361.png',
  noLink,
  alt = '',
}) => {
  if (noLink) {
    return <img className={locals.banner} src={`https://cdn.minecraft-servers.gg/${src}`} alt={alt} />;
  }

  return (
    <Link className={className} to={`/server/${serverId}`}>
      <img className={locals.banner} src={`https://cdn.minecraft-servers.gg/${src}`} alt={alt} />
    </Link>
  );
};

export default ServerBanner;
