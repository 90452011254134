import React from 'react';

import Icon from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';

import ThumbsUpIcon from 'assets/icons/thumbs-up.svg';

import styles from './Recaps.scss';

const SecondaryInfo = ({ countryName, online, max, peak, votes, id }) => {
  return (
    <>
      <p>Players</p>
      <p>
        <span className="text-emphasis">{online}</span>/
        <span className="text-emphasis">{max}</span>
      </p>
      <hr />

      <p>Votes</p>
      <p className="text-emphasis">{votes}</p>

      <hr />
      <Button className={styles.vote} to={`/server/${id}/vote`}>
        <Icon icon={ThumbsUpIcon} />
        <span>Vote</span>
      </Button>
    </>
  );
};

export default SecondaryInfo;
