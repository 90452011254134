import React from 'react';
import { Navigate } from 'react-router-dom';

import API from 'api/API';
import Button from 'components/common/Button/Button';
import LoadingButton from 'components/common/Button/LoadingButton';
import MonoInput from 'components/forms/Input/MonoInput';
import Errors from 'components/forms/Input/Errors';

import KeyIcon from 'assets/icons/key.svg';

import styles from './Authentication.scss';

export default class MFA extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      // Fields
      ticket: props.ticket,
      mfaCode: '',
      error: undefined,
      // API
      loading: false,
      success: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { ticket, mfaCode } = this.state;

    // Do API request
    API.post('/auth/totp', { ticket, code: mfaCode }, this).then((json) => {
      if (json.code || json.error) {
        this.setState({ success: false, error: json.error });
      } else {
        localStorage.setItem('user', JSON.stringify(json.user));
        localStorage.setItem('token', json.token);
        this.setState({ success: true });
      }
    });
  }

  handleChange(e) {
    const { name, value } = e.target;

    if (/^(\d{0,6}|([a-z0-9]{0,4}-){0,3}[a-z0-9]{0,4})$/.test(value)) {
      this.setState({ [name]: value });
    }
  }

  render() {
    const { loading, success, error, mfaCode } = this.state;

    if (success) {
      window.location = '/';
      return <Navigate to="/" />;
    }

    return (
      <form
        method="POST"
        className={styles.wrapper}
        onSubmit={this.handleSubmit}
      >
        <h3>Login</h3>

        <div className={styles.group}>
          <label htmlFor="mfaCode">2FA Code</label>
          <MonoInput
            icon={KeyIcon}
            type="text"
            name="mfaCode"
            value={mfaCode}
            placeholder="000000"
            helper="Enter your 2FA code or recovery code"
            autoComplete="one-time-code"
            onChange={this.handleChange}
            className={styles.mfa}
          />
        </div>

        {error && <Errors error={error} />}

        <div className={styles.group}>
          {loading ? <LoadingButton /> : <Button type="submit">Login</Button>}
        </div>
      </form>
    );
  }
}
