import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'components/common/Icon/Icon';
import styles from './Menu.scss';

function MenuLink({ icon, text, to, onClick }) {
  return (
    <NavLink
      className={(nav) =>
        nav.isActive ? `${styles.navlink} ${styles.active}` : styles.navlink
      }
      to={to}
      onClick={onClick}
    >
      <Icon icon={icon} />
      <span className={styles['link-text']}>{text}</span>
    </NavLink>
  );
}

export default MenuLink;
