import React from 'react';
import styles from './TagList.scss';

const TagList = ({ className, children }) => {
  const classes = [styles.list, className && className].join(' ');

  return <ul className={classes}>{children}</ul>;
};

export default TagList;
