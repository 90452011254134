import React from 'react';
import Icon from 'components/common/Icon/Icon';
import CheckIcon from 'assets/icons/check.svg';
import styles from './AddServer.scss';

const Progress = ({ currentStep, steps, goToStep }) => {
  const handleKeyDown = (e, i) => {
    const { key } = e;
    if (key === 'Enter' || key === ' ') goToStep(i);
  };

  return (
    <ol className={styles.progress}>
      {Object.values(steps).map(({ label, number }) => (
        <li
          className={
            (number === currentStep && styles.active) ||
            (number < currentStep && styles.done) ||
            ''
          }
          key={label}
        >
          <div
            className={styles.step}
            role="button"
            tabIndex="0"
            onClick={() => goToStep(number)}
            onKeyDown={(e) => handleKeyDown(e, number)}
          >
            <span className={styles.label}>{label}</span>
            <div className={styles['step-indicator']}>
              {number < currentStep && (
                <Icon className={styles.icon} icon={CheckIcon} />
              )}
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};

export default Progress;
