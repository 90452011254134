import React from 'react';
import { Navigate } from 'react-router-dom';

import API from 'api/API';
import Button from 'components/common/Button/Button';
import LoadingButton from 'components/common/Button/LoadingButton';
import FormValidation from 'validation/FormValidation';
import TextInput from 'components/forms/Input/TextInput';
import Errors from 'components/forms/Input/Errors';
import { signinValidation } from 'validation/Config';

import MFA from 'pages/Authentication/MFA';

import MailIcon from 'assets/icons/mail.svg';
import KeyIcon from 'assets/icons/key.svg';

import styles from './Authentication.scss';

export default class LogIn extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      // Fields
      email: '',
      password: '',
      errors: {},
      // API
      loading: false,
      success: false,
      submitting2fa: false,
    };

    this.validation = new FormValidation(this, signinValidation);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;

    // Do API request
    API.post('/auth/login', { email, password }, this).then((json) => {
      if (json.code || json.error) {
        this.showError(json.error);
      } else if (json.ticket) {
        this.setState({ mfaTicket: json.ticket });
      } else {
        this.setState({ success: true });

        localStorage.setItem('user', JSON.stringify(json.user));
        localStorage.setItem('token', json.token);
      }
    });
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
    this.validation.validate(this.state);
  }

  handleBlur() {
    this.validation.validate(this.state);
  }

  // This shows a "general" error. This appears at the bottom of the form
  showError(error) {
    this.setState({ errors: { general: error } });
  }

  render() {
    const { email, password, errors, loading, success, mfaTicket } = this.state;

    if (mfaTicket) {
      return <MFA ticket={mfaTicket} />;
    }

    if (success) {
      // This is a hacky solution but it works.
      // Menu checks for login however, we aren't changing the state meaning it isn't being re-rendered
      // So, we need to reload the page.
      window.location = '/';
      return <Navigate to="/" />;
    }

    return (
      <form
        method="POST"
        className={styles.wrapper}
        onSubmit={this.handleSubmit}
      >
        <h3>Login</h3>

        <div className={styles.group}>
          <label htmlFor="email">Email address</label>
          <TextInput
            icon={MailIcon}
            type="email"
            name="email"
            value={email}
            autoComplete="email"
            errors={errors.email}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </div>

        <div className={styles.group}>
          <label htmlFor="password">Password</label>
          <TextInput
            icon={KeyIcon}
            type="password"
            name="password"
            value={password}
            autoComplete="current-password"
            errors={errors.password}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </div>

        {errors.general && <Errors error={errors.general} />}

        <div className={styles.group}>
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              type="submit"
              disabled={!this.validation.isSubmittable(this.state)}
            >
              Login
            </Button>
          )}
        </div>
      </form>
    );
  }
}
