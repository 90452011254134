import React from 'react';

import DiscordIcon from 'assets/social/Discord.svg';
import TwitterIcon from 'assets/social/Twitter.svg';
import InstagramIcon from 'assets/social/Instagram.svg';

import SocialIcon from './SocialIcon';
import LinkSection from './LinkSection';

import styles from './Footer.scss';

const helpLinks = [
  { text: 'About', url: '/about' },
  { text: 'FAQ', url: '/faq' },
  { text: 'Contact', url: '/contact' },
];

const infoLinks = [
  { text: 'Blog', url: '/blog' },
  { text: 'Terms of service', url: '/terms' },
  { text: 'Privacy policy', url: '/privacy' },
];

const currentYear = new Date().getFullYear();
const copy = `Minecraft Servers GG © ${currentYear}. All rights reserved.`;

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <SocialIcon
          url="https://mcsgg.com/discord"
          id={styles.discord}
          icon={<DiscordIcon alt="Discord" />}
        />
        <SocialIcon
          url="https://mcsgg.com/twitter"
          id={styles.twitter}
          icon={<TwitterIcon alt="Twitter" />}
        />
        <SocialIcon
          url="https://mcsgg.com/instagram"
          id={styles.instagram}
          icon={<InstagramIcon alt="Instagram" />}
        />
        <p className={styles.copy}>{copy}</p>
      </div>

      <LinkSection label="Help" links={helpLinks} />
      <LinkSection label="Information" links={infoLinks} />
    </footer>
  );
};

export default Footer;
