import React, { useState, useEffect } from 'react';
// import { Prompt } from 'react-router-dom';

import Header from 'components/common/Header/Header';
import Button from 'components/common/Button/Button';
import Icon from 'components/common/Icon/Icon';

import ArrowRightIcon from 'assets/icons/arrow-right.svg';
import ArrowLeftIcon from 'assets/icons/arrow-left.svg';
import CheckIcon from 'assets/icons/check.svg';

// mock tag data
import data from 'data/tags.json';
import { countries } from 'data/countries';

import useForm from 'validation/useForm';
import Progress from './Progress';
import General from './Steps/General';
import Votifier from './Steps/Votifier';
// import Webhooks from './Steps/Webhooks';
import Review from './Steps/Review';

import styles from './AddServer.scss';

/*
 * TODO:
 * - [ ] disable next button if current step invalid
 * - [ ] submit to API
 * - [ ] re-enable webhooks + webhooks validation
 */

const steps = [
  { number: 1, label: 'General', component: General },
  { number: 2, label: 'Votifier', component: Votifier },
  // { number: 3, label: 'Webhooks', component: Webhooks },
  { number: 3, label: 'Review', component: Review },
];

const restrictions = {
  size: 1000000,
  types: ['image/png', 'image/gif', 'image/jpeg'],
  resolution: ['468', '60'],
};

const domainRegex =
  '(?!-)^(([A-Za-z0-9])+([-]{1}[A-Za-z0-9]+)*\\.)+[A-Za-z]{2,10}$';

const hostnameConfig = {
  maxLength: {
    value: 40,
    message: 'Must be shorter than 40 characters',
  },
  pattern: {
    value: new RegExp(`^(([0-9]{1,3})\\.){3}[0-9]{1,3}$|${domainRegex}`),
    message: 'Enter a valid IP or hostname',
  },
};

const portConfig = {
  number: {
    max: 65535,
    message: 'Enter a valid port number',
  },
  pattern: {
    value: /(?!0)^[0-9]{1,5}$/,
    message: 'Enter a valid port number',
  },
};

const configuration = {
  name: {
    required: true,
    minLength: {
      value: 2,
      message: 'Use at least 2 characters',
    },
    maxLength: {
      value: 40,
      message: 'Must be shorter than 40 characters',
    },
  },
  banner: {
    required: true,
    restrictions,
  },
  ip: {
    ...hostnameConfig,
    required: true,
  },
  port: portConfig,
  description: {
    required: true,
    minLength: {
      value: 50,
      message: 'Use at least 50 characters',
    },
    maxLength: {
      value: 4000,
      message: 'Must be shorter than 4000 characters',
    },
  },
  tags: {
    required: true,
    minLength: {
      value: 2,
      message: 'You should include at least 2 tags',
    },
  },
  country: {
    required: true,
  },
  website: {
    pattern: {
      value: new RegExp(domainRegex),
      message: 'Enter a valid domain name',
    },
    maxLength: {
      value: 250,
      message: 'Must be shorter than 250 characters',
    },
  },
  discord: {
    maxLength: {
      value: 25,
      message: 'Discord invite links cannot be longer than 25 characters',
    },
  },
  twitter: {
    pattern: {
      value: /^[A-Za-z0-9_]+$/,
      message: 'Twitter usernames only contain letters, numbers or underscores',
    },
    maxLength: {
      value: 15,
      message: 'Twitter usernames cannot be longer than 15 characters',
    },
  },
  instagram: {
    pattern: {
      value: /^[A-Za-z0-9_.]+$/,
      message: 'Enter a valid Instagram username',
    },
    maxLength: {
      value: 30,
      message: 'Instagram usernames cannot be longer than 30 characters',
    },
  },
  votifierIp: hostnameConfig,
  votifierPort: { ...portConfig }, // deconstruct so we can require this without also requiring server port
  votifierKey: {},
  // webhooksUrl: {},
};

const tagSuggestions = Object.entries(data).map(([key, value]) => ({
  id: key,
  label: value,
}));

const countrySuggestions = Object.entries(countries).map(([key, value]) => ({
  id: key,
  label: value,
}));

const initialValues = {
  name: '',
  banner: '',
  ip: '',
  port: '',
  description: '',
  tags: [],
  country: '',
  website: '',
  discord: '',
  twitter: '',
  instagram: '',
  votifierEnabled: true,
  votifierSameIp: true,
  votifierIp: '',
  votifierPort: '8192',
  votifierKey: '',
  /*   webhooksEnabled: true,
  webhooksUrl: '',
  events: {
    event1: { checked: true, label: 'Event 1' },
    event2: { checked: true, label: 'Event 2' },
    event3: { checked: true, label: 'Event 3' },
  }, */
};

const AddServer = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const {
    values,
    errors,
    submitting,
    submitted,
    handleChange,
    handleBlur,
    isFormValid,
  } = useForm({
    configuration,
    initialValues,
  });

  // handle form navigation and editing values at last step
  const goToStep = (step) => {
    setCurrentStep(step);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (currentStep === steps.length) {
      console.log(values);
    } else {
      goToStep(currentStep + 1);
    }
  };

  // scroll back to top when switching steps,
  // sometimes form is long for device height
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  // make votifier fields mandatory if enabled
  const { votifierEnabled } = values;

  useEffect(() => {
    configuration.votifierIp.required = votifierEnabled;
    configuration.votifierPort.required = votifierEnabled;
    configuration.votifierKey.required = votifierEnabled;
  }, [votifierEnabled]);

  // render button go to previous step
  const PrevButton = () => {
    if (currentStep <= 1) return null;

    return (
      <Button
        color="hollow"
        onClick={() => goToStep(currentStep - 1)}
        className={styles.button}
      >
        <Icon icon={ArrowLeftIcon} />
        Back
      </Button>
    );
  };

  // render button to go to next step, or submit at last step
  const NextButton = ({ form }) => {
    if (currentStep === steps.length) {
      return (
        <Button className={styles.button} form={form}>
          <Icon icon={CheckIcon} />
          Add server
        </Button>
      );
    }

    return (
      <Button className={styles.button} form={form}>
        Next <Icon icon={ArrowRightIcon} />
      </Button>
    );
  };

  return (
    <>
      {/* TODO: Prompt is not supported currently in react-router-dom v6 - we need to wait for that or find an alternative */}
      {/* <Prompt message="Are you sure you want to leave ? Your changes will not be saved." /> */}

      <Header text="Add your server to the list" />

      <Progress currentStep={currentStep} steps={steps} goToStep={goToStep} />

      <form
        id="server-form"
        className={styles.section}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {steps.map((step) => (
          <step.component
            key={step.label}
            currentStep={currentStep}
            stepNumber={step.number}
            values={values}
            errors={errors}
            restrictions={restrictions}
            tags={tagSuggestions}
            countries={countrySuggestions}
            handleChange={handleChange}
            handleBlur={handleBlur}
            goToStep={goToStep}
          />
        ))}
      </form>

      <div className={styles.buttons}>
        <PrevButton form="server-form" />
        <NextButton form="server-form" />
      </div>
    </>
  );
};

export default AddServer;
