import React from 'react';

import Flag from 'components/common/Flag';
import ServerBanner from 'components/servers/ServerBanner/ServerBanner';

import styles from './Header.scss';

const Header = ({ countryCode, countryName, text }) => {
  return (
    <header className={styles.header}>
      <div className={styles.title}>
        {countryCode && (
          <Flag
            className={styles.flag}
            countryCode={countryCode || 'US'}
            style={{ height: 'unset', width: 'unset' }}
            alt={`Flag of ${countryName}`}
            svg
          />
        )}
        <h2>{text}</h2>
      </div>
      {countryCode && (
        <div className={styles.banner}>
          <ServerBanner alt={`Banner of ${text}`} noLink />
        </div>
      )}
    </header>
  );
};

export default Header;
