import React from 'react';
import { fromUnixTime, format } from 'date-fns';
import { Link } from 'react-router-dom';

import useServerInfo from 'components/servers/useServerInfo';
import ServerInfo from 'components/servers/ServerInfo/ServerInfo';
import Icon from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';

import PlusCircleIcon from 'assets/icons/plus-circle.svg';
import EyeIcon from 'assets/icons/eye.svg';
import StarIcon from 'assets/icons/star.svg';
import PencilIcon from 'assets/icons/pencil.svg';

import styles from './Server.scss';

const Server = ({ ongoingAuction }) => {
  const server = localStorage.getItem('server');

  if (!server) {
    return <NoServerNotice />;
  }

  const data = useServerInfo(server.id);

  return (
    <>
      {ongoingAuction && <OngoingAuctionNotice />}
      {data.isSponsored && (
        <SponsorshipActiveNotice date={data.sponsoredUntil} />
      )}
      <ServerActions serverId={server.id} />

      <ServerInfo serverId={server.id} />
    </>
  );
};

/*
 * Server action links
 */
const ServerActions = ({ serverId }) => {
  return (
    <div className={styles.actions}>
      <Link to="/dashboard/server/edit" className="action link-muted">
        <Icon icon={PencilIcon} />
        <span>Edit server</span>
      </Link>

      <Link to={`/server/${serverId}`} className="action link">
        <Icon icon={EyeIcon} />
        <span>View server page</span>
      </Link>
    </div>
  );
};

/*
 * Notifies the user that they don't have a server
 */
const NoServerNotice = () => {
  return (
    <div className="notice">
      <p>You have no registered server.</p>

      <div className="button">
        <Button to="/dashboard/server/add">
          <Icon icon={PlusCircleIcon} />
          Add my server
        </Button>
      </div>
    </div>
  );
};

/*
 * Displays sponsorship end date
 */
const SponsorshipActiveNotice = ({ date }) => {
  return (
    <div className="notice-sponsored">
      <p>
        <Icon icon={StarIcon} />
        Server sponsorship active until{' '}
        {format(fromUnixTime(date), 'MMMM d, yyyy')}
      </p>
    </div>
  );
};

/*
 * Informs about a currently ongoing auction
 */
const OngoingAuctionNotice = () => {
  return (
    <div className="notice-sponsored">
      <p>There is an ongoing auction for a sponsored server slot.</p>

      <div className="button">
        <Button color="sponsored" to="/get-sponsored">
          <Icon icon={EyeIcon} />
          View auction
        </Button>
      </div>
    </div>
  );
};

export default Server;
