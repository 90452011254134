import Constants from 'utils/Constants';

export default class API {
  static async get(path, component) {
    console.log(`get(${path})`);
    return API.root(path, 'GET', undefined, component);
  }

  static async getData(path, component) {
    console.log(`getData(${path})`);
    return API.root(path, 'GET', undefined, component).then((json) => {
      component.setState({
        data: json,
      });
      return json;
    });
  }

  static async post(path, body, component) {
    console.log(`post(${path})`);
    return API.root(path, 'POST', body, component);
  }

  static async put(path, body, component) {
    console.log(`put(${path})`);
    return API.root(path, 'PUT', body, component);
  }

  static async root(requestedPath, method, body, component) {
    console.log(`root(${requestedPath}, ${method}, ${JSON.stringify(body)})`);

    if (component) {
      component.setState({
        loading: true,
      });
    }

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Client: `${Constants.HASH}:${Constants.BRANCH}`,
      },
    };

    const token = localStorage.getItem('token');
    if (token !== undefined && token !== null) {
      options.headers.Authorization = token;
    }

    if (body !== undefined) {
      options.body = JSON.stringify(body);
    }

    let path = requestedPath;
    if (!path.startsWith('/auth')) {
      path = `/v1${path}`;
    }

    return fetch(`${Constants.API_URL}${path}`, options).then((res) => {
      if (component) {
        component.setState({ loading: false });
      }
      return res.json();
    });
  }
}
