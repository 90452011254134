import React from 'react';
import Button from 'components/common/Button/Button';

const Billing = () => {
  // if (!user.twoFactor) return <No2faNotice />;

  return <NoBillingNotice />;
};

/*
 * Temporary notice until billing is enabled
 */
const NoBillingNotice = () => {
  return (
    <div className="notice">
      <p>
        Bidding and billing will be added shortly. For now, you can manually bid
        and work out payments on the{' '}
        <a href="https://pandiom.com/discord" className="link">
          Discord
        </a>
        .
      </p>
    </div>
  );
};

/*
 * Informs user that they don't have 2FA
 */
const No2faNotice = () => {
  return (
    <div className="notice">
      <p>
        Two-factor authentication is currently{' '}
        <span className="text-emphasis">inactive</span> on this account.
      </p>
      <p>You must enable it to make payments.</p>

      <div className="button">
        <Button to="/dashboard/2fa">Set up 2FA</Button>
      </div>
    </div>
  );
};

export default Billing;
