import React from 'react';
import styles from './Tag.scss';

const EditableTag = ({ name, onKeyDown, onMouseDown, onClick, isFocused }) => {
  return (
    <li className={styles.editable}>
      <button
        tabIndex="-1"
        type="button"
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        onClick={onClick}
        className={`${styles.content} ${isFocused ? styles['is-focused'] : ''}`}
      >
        <span>{name}</span>
        <span className={styles.delete}>×</span>
      </button>
    </li>
  );
};

export default EditableTag;
