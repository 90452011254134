import React, { isValidElement } from 'react';

import Icon from 'components/common/Icon/Icon';
import Errors from 'components/forms/Input/Errors';

import styles from './Input.scss';

/*
 * TODO: focus input on wrapper click (i.e. if extra or icon)
 */

const TextInput = ({
  dark,
  icon,
  extra,
  type = 'text',
  onChange,
  onBlur,
  onKeyDown,
  name,
  placeholder,
  value,
  errors,
  helper,
  disabled,
  className,
  noValidation,
}) => {
  const containerClasses = [className, errors && styles.invalid].join(' ');
  const wrapperClasses = [
    styles.wrapper,
    dark && styles.dark,
    disabled && styles.disabled,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={containerClasses}>
      <div className={wrapperClasses}>
        {icon && <Icon icon={icon} className={styles.icon} />}
        {extra && <span className={styles.extra}>{extra}</span>}

        <input
          type={type}
          className={styles.input}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
        />
      </div>

      {helper &&
        (isValidElement(helper) ? (
          helper
        ) : (
          <span className="text-helper">{helper}</span>
        ))}

      {!noValidation && errors && <Errors error={errors} />}
    </div>
  );
};

export default TextInput;
