import API from 'api/API';

const PROFILE_CACHE_TIME = 3_600_000;

export async function getProfile() {
  const cache = localStorage.getItem('profile');

  if (
    cache === null ||
    !cache.lastUpdated ||
    cache.lastUpdated + PROFILE_CACHE_TIME < Date.now()
  ) {
    const data = await API.get('/profile');

    data.lastUpdated = Date.now();

    // Cache the user and server data
    localStorage.setItem('profile', JSON.stringify(data));

    return data;
  }

  return cache;
}

export async function getUser() {
  const profile = await getProfile();

  if (profile && profile.user) {
    return profile.user;
  }
  return null;
}

export async function getServer() {
  const profile = await getProfile();

  if (profile && profile.server) {
    return profile.server;
  }
  return null;
}
