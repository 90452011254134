import React, { useState, useRef, isValidElement } from 'react';

import Icon from 'components/common/Icon/Icon';
import ServerBanner from 'components/servers/ServerBanner/ServerBanner';
import Errors from 'components/forms/Input/Errors';

import UploadIcon from 'assets/icons/upload.svg';
import XIcon from 'assets/icons/x.svg';
import RefreshIcon from 'assets/icons/refresh.svg';

import styles from './Input.scss';

const FileInput = (props) => {
  const [inDropZone, setInDropZone] = useState(false);
  const inputRef = useRef(null);

  const {
    preview,
    errors,
    name,
    restrictions: { types, resolution },
    handleChange,
    helper,
  } = props;

  const openFileBrowser = () => {
    inputRef.current.click();
  };

  const handleClick = () => {
    if (!preview) openFileBrowser();
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === 'Enter' || key === ' ') openFileBrowser();
  };

  const handleDrag = (e, value) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
    setInDropZone(value);
  };

  // handle drag & drop upload
  const handleDrop = (e) => {
    e.preventDefault();
    const dt = e.dataTransfer;
    dt.dropEffect = 'copy';

    setInDropZone(false);
    handleChange({
      target: { name, type: 'file', files: dt.files },
    });
  };

  // remove file on remove button click
  const handleRemove = (e) => {
    const { type, key } = e;

    if (type === 'click' || key === 'Enter' || key === ' ') {
      inputRef.current.value = '';
      handleChange({
        target: { name, type: 'file', files: [], action: 'remove' },
      });
    }
  };

  const dropZoneClasses = [
    styles.upload,
    inDropZone && styles.dragging,
    preview && styles['has-file'],
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={errors && styles.invalid}>
      <div
        className={dropZoneClasses}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onDragEnter={(e) => handleDrag(e, true)}
        onDragOver={(e) => handleDrag(e, true)}
        onDragLeave={(e) => handleDrag(e, false)}
        onDrop={handleDrop}
        role="button"
        tabIndex={preview ? '-1' : '0'}
      >
        {preview ? (
          <>
            <ServerBanner
              src={URL.createObjectURL(preview)}
              alt="Server banner"
              noLink
            />

            <div className={styles.actions}>
              <span
                role="button"
                tabIndex="0"
                onClick={handleRemove}
                onKeyDown={handleRemove}
                type="button"
                className="action link-delete"
              >
                <Icon icon={XIcon} />
                Remove
              </span>

              <span
                role="button"
                tabIndex="0"
                onClick={openFileBrowser}
                onKeyDown={handleKeyDown}
                type="button"
                className="action link"
              >
                <Icon icon={RefreshIcon} />
                Replace
              </span>
            </div>
          </>
        ) : (
          <>
            <Icon icon={UploadIcon} className={styles.icon} />
            <p className={styles.tip}>
              <span className="link">Choose an image</span> or drag it here.
            </p>
          </>
        )}

        {resolution && (
          <p className="text-muted">
            Recommended size: {resolution.join('x')}px
          </p>
        )}

        <input
          ref={inputRef}
          type="file"
          name={name}
          onChange={handleChange}
          accept={types}
          hidden
        />
      </div>

      {helper &&
        (isValidElement(helper) ? (
          helper
        ) : (
          <span className="text-helper">{helper}</span>
        ))}

      <Errors error={errors} />
    </div>
  );
};

export default FileInput;
