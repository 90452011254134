import React from 'react';
import Icon from 'components/common/Icon/Icon';

import styles from './SectionTitle.scss';

const SectionTitle = ({ icon, text }) => {
  return (
    <div className={styles.title}>
      {icon && <Icon icon={icon} className={styles.icon} />}
      <h3 className={styles.text}>{text}</h3>
    </div>
  );
};

export default SectionTitle;
