import React from 'react';

import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import Errors from 'components/forms/Input/Errors';
import TextInput from 'components/forms/Input/TextInput';
import Button from 'components/common/Button/Button';
import LoadingButton from 'components/common/Button/LoadingButton';
import Icon from 'components/common/Icon/Icon';

import API from 'api/API';
import { isMfaEnabled } from 'utils/Flags';
import FormValidation from 'validation/FormValidation';
import { securityValidation } from 'validation/Config';

import ShieldCheck from 'assets/icons/shield-check.svg';
import ShieldExclamationIcon from 'assets/icons/shield-exclamation.svg';

import styles from './Account.scss';

export default class Account extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      loading: false,
      updated: false,

      // Fields
      password: '',
      newPassword: '',
      confirmPassword: '',

      // Data
      user: props.user,
      errors: {},
    };

    this.validation = new FormValidation(this, securityValidation);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
    this.validation.validate(this.state);
  }

  handleBlur() {
    this.validation.validate(this.state);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { password, newPassword, confirmPassword } = this.state;

    // Do API request
    API.put('/profile/password', { password, newPassword, confirmPassword }, this)
      .then((obj) => {
        const newState = {};

        if (obj.error) {
          newState.errors = { confirmPassword: obj.error };
        } else {
          // It updated successfully
          newState.updated = true;

          newState.password = '';
          newState.newPassword = '';
          newState.confirmPassword = '';
        }

        this.setState(newState);
        setTimeout(() => this.setState({ updated: false }), 3000);
      })
  }

  render() {
    const {
      loading,
      updated,
      errors,
      user,
      password,
      newPassword,
      confirmPassword
    } = this.state;

    const mfaEnabled = isMfaEnabled(user.flags);

    return (
      <div className={styles.section}>
        <SectionTitle text="Security" />

        <form className={styles.security} method="POST" onSubmit={this.handleSubmit}>
          <div className={styles['input-group']}>
            <label htmlFor="current">Current password</label>
            <TextInput
              name="password"
              type="password"
              errors={errors.password}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={password}
            />
          </div>

          <div className={styles['input-group']}>
            <label htmlFor="new">New password</label>
            <TextInput
              name="newPassword"
              type="password"
              errors={errors.newPassword}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={newPassword}  
            />
          </div>

          <div className={styles['input-group']}>
            <label htmlFor="confirm">Confirm new password</label>
            <TextInput
              name="confirmPassword"
              type="password"
              errors={errors.confirmPassword}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={confirmPassword}
            />
          </div>

          <div className={styles['input-group']}>
            { loading ? <LoadingButton className={styles.button} />
              : <Button
                className={styles.button}
                color="hollow"
                disabled={!this.validation.isSubmittable(this.state)}
              >
                { updated ? 'Updated!' : 'Change password' }
              </Button>
            }
          </div>
        </form>

        <div id={styles['two-factor']} className={styles.security}>
          <p>Two-factor authentication</p>

          <div className={mfaEnabled ? styles.active : styles.inactive}>
            <Icon
              className={styles.icon}
              icon={mfaEnabled ? ShieldCheck : ShieldExclamationIcon}
            />
            <span className={styles.text}>
              {mfaEnabled ? 'Active' : 'Inactive'}
            </span>
            {!mfaEnabled && (
              <p className="text-accent">
                This feature is required to make payments.
              </p>
            )}
          </div>

          <div className={styles['center-group']}>
            <Button
              className={styles.button}
              to="/dashboard/2fa"
              color={mfaEnabled ? 'hollow' : ''}
            >
              {mfaEnabled ? 'Update 2FA settings' : 'Set up 2FA'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
