import React from 'react';

import Icon from 'components/common/Icon/Icon';
import RefreshIcon from 'assets/icons/refresh.svg';

import Button from './Button';
import styles from './Button.scss';

const LoadingButton = ({ icon, children, className }) => {
  return (
    <Button color="hollow" className={className} disabled>
      {icon || <Icon icon={RefreshIcon} className={styles.spin} />}
      {children || 'Submitting...'}
    </Button>
  );
};

export default LoadingButton;
