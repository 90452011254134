import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ScrollRestoration from 'components/common/ScrollRestoration/ScrollRestoration';
import Menu from 'components/common/Menu/Menu';

import ServerList from 'pages/Home/Home';
import Server from 'pages/Server/Server';
import Dashboard from 'pages/Dashboard/Dashboard';
import SignUp from 'pages/Authentication/SignUp';
import LogIn from 'pages/Authentication/LogIn';
import Logout from 'pages/Authentication/Logout';

import Footer from 'components/common/Footer/Footer';

import styles from './App.scss';

const routes = [
  {
    id: 'Most Popular',
    path: '/',
    main: ServerList,
  },
  {
    id: 'Recently Bumped',
    path: '/recently-bumped',
    main: ServerList,
    props: { type: 'recently-bumped' },
  },
  {
    id: 'Server',
    path: '/server/:id',
    main: Server,
  },
  {
    id: 'Dashboard',
    path: '/dashboard/*',
    main: Dashboard,
  },
  {
    id: 'Sign up',
    path: '/signup',
    main: SignUp,
  },
  {
    id: 'Login',
    path: '/login',
    main: LogIn,
  },
  {
    id: 'Logout',
    path: '/logout',
    main: Logout,
  },
];

function App() {
  return (
    <Router>
      <ScrollRestoration />
      <Menu />

      <main className={styles.main}>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.main extra={route.props} />}
            />
          ))}
        </Routes>
      </main>

      <Routes>
        {routes.map((route) => (
          // TODO: Improve this
          <Route key={route.id} path={route.path} element={<Footer />} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
