import * as Utils from 'utils/Utils';

export default class FormValidation {
  constructor(component, config) {
    this.component = component;
    this.config = config;
  }

  validate(state, updateState = true) {
    const errors = {};
    Object.keys(this.config).forEach((field) => {
      // This is a hacky fix - find a better way later!
      // This will make sure we only ever have 1 error at a time.
      // This is to prevent all the fields showing errors or as required all at once.
      if (!Utils.isObjectEmpty(errors)) {
        return;
      }

      const validation = this.config[field];
      const { required, trim, minLength, maxLength, pattern, matchesField } =
        validation;

      let input = this.component.state[field] ?? '';
      if (!trim) {
        input = input.trim();
      }

      if (!validation) return;

      if (required && input.length === 0) {
        errors[field] = 'This is a required field!';
      } else if (minLength && input.length < minLength.value) {
        errors[field] = minLength.message;
      } else if (maxLength && input.length > maxLength.value) {
        errors[field] = maxLength.message;
      } else if (pattern && !input.match(pattern.value)) {
        errors[field] = pattern.message;
      } else if (matchesField && input !== state[matchesField.value]) {
        errors[field] = matchesField.message;
      } else {
        delete errors[field];
      }
    });

    if (updateState) {
      this.component.setState({ errors });
    }

    return Utils.isObjectEmpty(errors);
  }

  isSubmittable(state) {
    return this.validate(state, false);
  }
}
