import React from 'react';
import styles from './Input.scss';
import Errors from './Errors';

const TextArea = ({
  className,
  dark,
  onChange,
  onBlur,
  rows,
  name,
  placeholder,
  value,
  required,
  minLength,
  maxLength,
  errors,
}) => {
  const wrapperClasses = [styles.wrapper, dark && styles.dark, className].join(
    ' '
  );

  return (
    <div className={errors && styles.invalid}>
      <div className={wrapperClasses}>
        <textarea
          className={styles.input}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          value={value}
          required={required}
        />
      </div>

      <div className={styles.helpers}>
        {errors ? (
          <Errors error={errors} />
        ) : (
          <span className="text-helper">Minimum {minLength} characters</span>
        )}

        {maxLength && (
          <span className="text-helper">
            {value.length}/{maxLength}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextArea;
