import React, { useState, useRef } from 'react';
import styles from './Copyable.scss';

const Copyable = ({
  extra,
  className,
  text,
  successText = 'Copied!',
  timeout = 2000,
}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const inputEl = useRef(null);

  const classes = [styles.copyable, className].join(' ');

  const copyToClipboard = () => {
    if (!copySuccess) {
      inputEl.current.select();
      document.execCommand('copy');
      setCopySuccess(successText);

      setTimeout(() => {
        setCopySuccess('');
      }, timeout);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') copyToClipboard();
  };

  return (
    <div
      className={classes}
      onClick={copyToClipboard}
      onKeyUp={handleKeyUp}
      role="button"
      tabIndex="0"
    >
      {extra}
      <input
        type="text"
        className={styles.input}
        style={{ width: `${text.length}ch` }}
        ref={inputEl}
        value={copySuccess || text}
        readOnly
      />
    </div>
  );
};

export default Copyable;
