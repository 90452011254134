import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Tag.scss';

const Tag = ({ name, plain, noLink }) => {
  const Element = noLink ? 'span' : Link;

  return (
    <li className={`${styles.tag} ${plain ? styles.plain : ''}`}>
      <Element className={styles.content} to={`/tag/${name}`}>
        {name}
      </Element>
    </li>
  );
};

export default Tag;
