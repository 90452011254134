import React from 'react';
import styles from './Status.scss';

const Status = ({ className, online, noText }) => {
  const indicatorClasses = [
    styles.indicator,
    online ? styles.online : styles.offline,
    !noText ? styles['has-text'] : '',
  ].join(' ');

  return (
    <>
      <span className={indicatorClasses} />
      {!noText && (
        <span className={className}>{online ? 'ONLINE' : 'OFFLINE'}</span>
      )}
    </>
  );
};

export default Status;
