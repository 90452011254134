import React from 'react';
import { Link } from 'react-router-dom';

import Flag from 'components/common/Flag';
import Copyable from 'components/common/Copyable/Copyable';
import StarIcon from 'assets/icons/star.svg';

import ServerBanner from '../ServerBanner/ServerBanner';
import Status from '../Status/Status';
import Tag from '../Tag/Tag';
import TagList from '../TagList/TagList';

import { isSponsored } from 'utils/Flags';

import styles from './ServerRow.scss';

const ServerRow = ({
  rank,
  data
}) => {
  const sponsored = isSponsored(data.flags);

  const classes = `${styles.server}${sponsored ? ' ' + styles['is-sponsored']: ''}`;

  return (
    <li className={classes}>
      <ServerBanner serverId={data.id} className={styles.banner} src={data.banner} />

      <div className={styles.badges}>
        <p className={styles.rank}>
          {sponsored ? <StarIcon className={styles.star} /> : `#${rank}`}
        </p>
        <Flag
          className={styles.flag}
          countryCode={data.countryCode}
          style={{ height: 'unset', width: 'unset' }}
          svg
        />
      </div>

      <div className={styles.recap}>
        <Link to={`/server/${data.id}`} className={styles['name-link']}>
          <h1 className={styles.name}>{data.name}</h1>
        </Link>

        <TagList>
          <li className={styles.tidbit} key="copy">
            <Copyable
              extra={<Status online={data.status === 'online'} noText />}
              className={styles.content}
              text={data.ip}
            />
          </li>
          <li key="version" className={`text-accent ${styles.tidbit}`}>
            <span className={styles.content}>{data.version}</span>
          </li>
          <li key="players" className={styles.tidbit}>
            <span className={styles.content}>
              <span className="text-accent">{data.playersOnline}</span>/
              <span className="text-accent">{data.playersMax}</span>
              &nbsp;online
            </span>
          </li>
        </TagList>

        {data.tags && (
          <TagList>
            {data.tags.map((tag) => (
              <Tag name={tag} key={tag} />
            ))}
          </TagList>
        )}
      </div>
    </li>
  );
};

export default ServerRow;
