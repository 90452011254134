import React from 'react';

const SocialIcon = ({ id, url, icon }) => {
  return (
    <a id={id} href={url}>
      {icon}
    </a>
  );
};

export default SocialIcon;
