import React from 'react';
import { Navigate } from 'react-router-dom';

export default function Logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location = '/';

  return <Navigate to="/" />;
}
