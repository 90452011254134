import React from 'react';
import styles from './SocialLink.scss';

const SocialLink = ({ href, icon, text }) => {
  const site = text.toLowerCase();

  const classes = [styles.link, styles[site]].join(' ');

  return (
    <a href={href} className={classes}>
      <div className={styles.icon}>{icon}</div>
      <p className={styles.text}>{text}</p>
    </a>
  );
};

export default SocialLink;
