import React from 'react';
import Status from 'components/servers/Status/Status';

const StatusInfo = ({ pingSuccess, timeSinceLastPing }) => {
  return (
    <>
      <p>
        <Status className="text-emphasis" online={pingSuccess} />
      </p>
      <p>
        {timeSinceLastPing ? (
          <>
            <span>Last pinged </span>
            <span className="text-emphasis">{timeSinceLastPing}</span>
            <span> ago</span>
          </>
        ) : (
          'Last ping unavailable'
        )}
      </p>
    </>
  );
};

export default StatusInfo;
