import React from 'react';
import { useParams } from 'react-router-dom';

import ServerInfo from 'components/servers/ServerInfo/ServerInfo';

const Server = () => {
  const { id } = useParams();

  return (
    <div className="content-columns">
      <ServerInfo serverId={id} />
    </div>
  );
};

export default Server;