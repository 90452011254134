const url = 'https://api.pwnedpasswords.com/range/';

const digestPassword = async (password) => {
  const msgUint8 = new TextEncoder().encode(password); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8); // hash the password
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string

  return hashHex;
};

export default async function pwnedPasswordCount(password) {
  const hash = await digestPassword(password);
  const prefix = hash.substring(0, 5).toUpperCase(); // first 5 characters to request
  const suffix = hash.substring(5).toUpperCase(); // rest of hash to look for in response

  const count = await fetch(url + prefix, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Add-Padding': true,
    },
  })
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      // API should respond with hundreds of rows in SUFFIX:COUNT format,
      // i.e. `00D4F6E8FA6EECAD2A3AA415EEC418D38EC:2`

      // find the row matching the suffix
      const found = res.split('\r\n').find((row) => row.includes(suffix));

      if (found) {
        // split match at colon to get number of times breached
        const number = found.split(':');
        return parseInt(number[1], 10);
      }

      return 0; // no match, so password never breached
    });

  return count;
}
