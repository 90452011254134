import React from 'react';
import styles from './Input.scss';

const ToggleInput = ({ onChange, name, checked }) => {
  return (
    <label htmlFor={name} className={styles.switch}>
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <span className={styles.slider} />
    </label>
  );
};

export default ToggleInput;
