import React from 'react';
import { useParams } from 'react-router-dom';

import Header from 'components/common/Header/Header';
import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import StatusInfo from 'components/servers/Recaps/StatusInfo';
import PrimaryInfo from 'components/servers/Recaps/PrimaryInfo';
import SecondaryInfo from 'components/servers/Recaps/SecondaryInfo';
import SocialLink from 'components/servers/SocialLink/SocialLink';
import TagList from 'components/servers/TagList/TagList';
import Tag from 'components/servers/Tag/Tag';
import LineGraph from 'components/servers/Chart/LineGraph';

import API from 'api/API';
import Spinner from 'components/common/Loading/Spinner';

import CogIcon from 'assets/icons/cog.svg';
import InformationCircleIcon from 'assets/icons/information-circle.svg';
import LinkIcon from 'assets/icons/link.svg';
import TagIcon from 'assets/icons/tag.svg';
import ExternalLinkIcon from 'assets/icons/external-link.svg';
import TrendingUpIcon from 'assets/icons/trending-up.svg';
import DiscordIcon from 'assets/social/Discord.svg';
import TwitterIcon from 'assets/social/Twitter.svg';

import styles from './ServerInfo.scss';

export default class ServerInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: props.data ? false : true,
      error: undefined,
      data: props.data ?? {}
    }
  }

  componentDidMount() {
    if (this.state.loading) {
      API.getData(`/servers/${this.props.serverId}`, this);
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />
    }

    const { data } = this.state;

    return (
      <>
        <Header
          countryCode={data.countryCode}
          countryName={data.countryName}
          text={data.name}
        />

        <aside className={styles.aside}>
          <SectionTitle icon={CogIcon} text="About this server" />
          <div id={styles.status} className={styles.container}>
            <StatusInfo
              pingSuccess={data.status === 'online'}
              timeSinceLastPing={data.lastPing}
            />
          </div>

          <div className={styles.container}>
            <PrimaryInfo version={data.version} ip={data.ip} />
          </div>

          <div className={`${styles.container} ${styles.full}`}>
            <SecondaryInfo
              online={data.playersOnline}
              max={data.playersMax}
              votes={data.votes}
              id={data.id}
            />
          </div>
        </aside>

        <div className={styles.main}>
          <SectionTitle icon={InformationCircleIcon} text="Server description" />
          <div className={styles.container}>
            {data.description &&
              data.description
                .split('\n')
                .map((paragraph) => <p key={paragraph}>{paragraph}</p>)}
          </div>

          {(data.website || data.discord || data.twitter) && (
            <SectionTitle icon={LinkIcon} text="Links" />
          )}
          <div className={styles.links}>
              {data.website && (
                <SocialLink
                  href={data.website}
                  icon={<ExternalLinkIcon />}
                  text="Website"
                />
              )}
              {data.discord && (
                <SocialLink
                  href={`https://discord.gg/${data.discord}`}
                  icon={<DiscordIcon />}
                  text="Discord"
                />
              )}
              {data.twitter && (
                <SocialLink
                  href={`https://twitter.com/${data.twitter}`}
                  icon={<TwitterIcon />}
                  text="Twitter"
                />
              )}
            </div>

          <SectionTitle icon={TagIcon} text="Tags" />
          <TagList>
            {data.tags && data.tags.map((tag) => <Tag name={tag} key={tag} />)}
          </TagList>
        </div>

        {!this.props.hideChart &&
          <div className={styles.charts}>
            <SectionTitle icon={TrendingUpIcon} text="Charts" />

            <div className={styles.container}>
              <LineGraph />
            </div>
          </div>
      }
      </>
    );
  }
}
