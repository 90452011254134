import * as React from 'react'
import PropTypes from 'prop-types'

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

function Flag({
    countryCode,
    style,
    svg,
    ...props
}) {
    if (typeof countryCode !== 'string') {
        return null
    }

    if (svg) {
        const flagUrl = `/flag/${countryCode.toLowerCase()}.svg`

        return (
            <img
                {...props}
                src={flagUrl}
                style={{
                    display: 'inline-block',
                    width: '1em',
                    height: '1em',
                    verticalAlign: 'middle',
                    ...style
                }}
            />
        )
    } else {
      const emoji = countryCode
          .toUpperCase()
          .replace(/./g, (char) =>
              String.fromCodePoint(char.charCodeAt(0) + OFFSET)
          )

      return (
            <span
                role='img'
                {...props}
                style={{
                    display: 'inline-block',
                    fontSize: '1em',
                    lineHeight: '1em',
                    verticalAlign: 'middle',
                    ...style
                }}
            >
                {emoji}
            </span>
        )
    }
}

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
    style: PropTypes.object,
    svg: PropTypes.bool
}

Flag.defaultProps = {
    svg: false
}

export default Flag