import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.scss';

const LinkSection = (props) => {
  const { label, links } = props;

  return (
    <div className={styles.links}>
      <h1 className={styles.label}>{label}</h1>
      <ul className="list-unstyled">
        {links.map((link) => (
          <li className={styles.item} key={link.text}>
            <Link className="link" to={link.url}>
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinkSection;
