import { useState } from 'react';

const useForm = ({ configuration, initialValues = {} }) => {
  if (!configuration) {
    throw new Error('Property `configuration` is required.');
  }

  if (typeof configuration !== 'object') {
    throw new Error('Property `configuration` must be an object.');
  }

  if (typeof initialValues !== 'object') {
    throw new Error('Property `initialValues` must be an object.');
  }

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [changed, setChanged] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const getError = (name, value) => {
    const rules = configuration[name];

    if (rules) {
      const {
        trim,
        required,
        pattern,
        minLength,
        maxLength,
        number,
        restrictions,
        callbacks,
      } = rules;

      // trim string values by default
      // unless `trim` is explicitly set to false
      const newValue =
        trim !== false && typeof value === 'string' ? value.trim() : value;

      /* return error for empty required fields,
       * but stop checking empty optional fields
       */
      if (!newValue || newValue.length === 0) {
        if (required) {
          return "This field can't be empty";
        }
        return '';
      }

      /* string/array length validation */
      if (minLength) {
        if (newValue.length < minLength.value) {
          return minLength.message;
        }
      }

      if (maxLength) {
        if (newValue.length > maxLength.value) {
          return maxLength.message;
        }
      }

      /* file validation */
      if (restrictions) {
        const roundedSize = Number.parseFloat(restrictions.size).toFixed(2);
        const readableSize = `${roundedSize / 1000000} MB`;

        if (value.size > restrictions.size) {
          return `File exceeds the maximum file size of ${readableSize}.`;
        }

        if (restrictions.types.every((type) => value.type !== type)) {
          const formats = restrictions.types.map(
            (type) => `.${type.match(/\w+$/)}`
          );

          return `File must be in one of the following formats: ${formats.join(
            ', '
          )}.`;
        }
      }

      // check number value
      if (number) {
        if (
          !Number.isNaN(Number(number.min)) &&
          !Number.isNaN(Number(number.max)) &&
          (Number.isNaN(Number(value)) ||
            value < number.min ||
            value > number.max)
        ) {
          return (
            number.message ||
            `Enter a number between ${number.min} and ${number.max}`
          );
        }

        if (
          !Number.isNaN(Number(number.min)) &&
          (Number.isNaN(Number(value)) || value < number.min)
        ) {
          return number.message || `Enter a number above ${number.min}`;
        }

        if (
          !Number.isNaN(Number(number.max)) &&
          (Number.isNaN(Number(value)) || value > number.max)
        ) {
          return number.message || `Enter a number below ${number.max}`;
        }
      }

      if (pattern) {
        if (!new RegExp(pattern.value).test(value)) {
          return pattern.message;
        }
      }

      // loop through array of custom validation functions
      // and return the first error
      if (callbacks && typeof callbacks === 'object') {
        // use untrimmed value,
        // let custom callbacks handle that
        const callback = Object.values(callbacks).find((fn) => fn(value));

        if (typeof callback === 'function') return callback(value);
      }
    }

    return '';
  };

  const updateErrors = (name, value) => {
    const error = getError(name, value);
    if (error === errors[name]) return;

    setErrors((state) => ({
      ...state,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    const hasErrors = Object.keys(configuration).some((name) =>
      Boolean(getError(name, values[name]))
    );
    return !hasErrors;
  };

  const handleChange = (e) => {
    const { type, files, action, checked, name, value } = e.target;

    setChanged((state) => ({
      ...state,
      [name]: true,
    }));

    switch (type) {
      case 'file':
        // instant feedback for file errors
        updateErrors(name, files[0]);

        if (getError(name, files[0]) && action !== 'remove') return;

        setValues((state) => ({
          ...state,
          [name]: files[0],
        }));
        break;

      case 'checkbox':
        setValues((state) => ({
          ...state,
          [name]: checked,
        }));

        break;

      default:
        setValues((state) => ({
          ...state,
          [name]: value,
        }));

        updateErrors(name, value);

        break;
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (changed[name]) return;
    updateErrors(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    // call post submit checks if needed
    const { postSubmit } = configuration;

    if (!postSubmit || typeof postSubmit !== 'function') {
      setSubmitted(true);
      return;
    }

    const fetchResponse = async () => {
      postSubmit(values).then((res) => {
        const { name, error } = res;

        setErrors({ ...errors, [name]: error });

        setSubmitting(false);

        if (!error) {
          setSubmitted(true);
        }
      });
    };

    fetchResponse();
  };

  return {
    values,
    errors,
    submitting,
    submitted,
    handleChange,
    handleSubmit,
    handleBlur,
    isFormValid,
  };
};

export default useForm;
