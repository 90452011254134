import React, { useState, useRef } from 'react';

import { Link } from 'react-router-dom';
import Button from 'components/common/Button/Button';
import { isLoggedIn } from 'utils/Utils';

import Logo from 'assets/branding/logo.svg';
import LogoText from 'assets/branding/logo-text.svg';
import HomeIcon from 'assets/icons/home.svg';
import BumpIcon from 'assets/icons/arrow-up.svg';
import DashboardIcon from 'assets/icons/dashboard.svg';
import FaqIcon from 'assets/icons/question-mark-circle.svg';
import ContactIcon from 'assets/icons/speech-bubble.svg';
import LogOutIcon from 'assets/icons/logout.svg';

import MenuLink from './MenuLink';

import styles from './Menu.scss';

function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef(null);

  const toggle = (e) => {
    if (e.type === 'click' || e.key === 'Enter') {
      setIsOpen(!isOpen);
    }

    btnRef.current.classList.add(styles.active);
    setTimeout(() => {
      btnRef.current.classList.remove(styles.active);
    }, 350);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header
      id={styles.menu}
      className={`${styles.menu} ${isOpen ? styles['is-open'] : ''}`}
    >
      <div className={styles.navbar}>
        <Link to="/" className={styles['navbar-item']}>
          <Logo className={styles.logo} aria-hidden />
          <LogoText
            className={styles['logo-text']}
            alt="Minecraft Servers GG Logo"
          />
        </Link>
        <button
          className={`${styles['navbar-item']} ${styles['menu-btn']}`}
          onClick={toggle}
          onKeyDown={toggle}
          tabIndex="0"
          aria-label="Mobile navigation"
          aria-controls="menu"
          aria-expanded={isOpen}
          ref={btnRef}
          type="button"
        >
          <div className={styles.slice} />
        </button>
      </div>

      <menu className={styles.navlinks} aria-hidden={!isOpen}>
        <ul className="list-unstyled" role="navigation" aria-label="Main">
          <MenuLink
            to="/"
            icon={HomeIcon}
            text="Most Popular"
            exact
            onClick={closeMenu}
          />
          <MenuLink
            to="/recently-bumped"
            icon={BumpIcon}
            text="Recent Bumps"
            exact
            onClick={closeMenu}
          />
          {isLoggedIn() && (
            <MenuLink
              to="/dashboard"
              icon={DashboardIcon}
              text="Dashboard"
              onClick={closeMenu}
            />
          )}
          <MenuLink
            to="/faq"
            icon={FaqIcon}
            text="FAQ"
            exact
            onClick={closeMenu}
          />
          <MenuLink
            to="/contact"
            icon={ContactIcon}
            text="Contact"
            exact
            onClick={closeMenu}
          />
        </ul>

        {isLoggedIn() ? (
          <ul className="list-unstyled" role="navigation" aria-label="Account">
            <MenuLink
              to="/logout"
              icon={LogOutIcon}
              text="Log out"
              onClick={closeMenu}
            />
          </ul>
        ) : (
          <ul className={styles.buttons}>
            <li className={styles.navitem}>
              <Button to="/signup">Sign up</Button>
            </li>
            <li className={styles.navitem}>
              <Button onClick={closeMenu} to="/login" color="hollow">
                Log in
              </Button>
            </li>
          </ul>
        )}
      </menu>
    </header>
  );
}

export default Menu;
