import React from 'react';
import styles from './Input.scss';

const RadioInput = ({ label, name, checked, onChange }) => {
  return (
    <label className={styles.check}>
      <input type="radio" name={name} checked={checked} onChange={onChange} />
      <span className={styles.radio} />
      {label}
    </label>
  );
};

export default RadioInput;
