import React from 'react';
import Icon from 'components/common/Icon/Icon';
import ExclamationCircleIcon from 'assets/icons/exclamation-circle.svg';
import styles from './Input.scss';

const Errors = ({ error }) => {
  return (
    <div className={styles.errors}>
      {error && (
        <>
          <Icon icon={ExclamationCircleIcon} className={styles.icon} />
          <span>{error}</span>
        </>
      )}
    </div>
  );
};

export default Errors;
