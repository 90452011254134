const drawBackgroundPlugin = {
  id: 'draw-background-plugin',
  beforeDraw: (chart) => {
    if (
      chart.config.options.chartArea &&
      chart.config.options.chartArea.backgroundColor
    ) {
      const { ctx } = chart;
      const { chartArea } = chart;

      console.log(chart);
      console.log(ctx);
      console.log(chartArea);

      ctx.save();
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    }
  },
};

export default drawBackgroundPlugin;
