import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  isValidElement,
} from 'react';

import TagList from 'components/servers/TagList/TagList';
import EditableTag from 'components/servers/Tag/EditableTag';
import Suggestions from 'components/forms/Input/Suggestions';

import styles from './Input.scss';
import Errors from './Errors';

const Select = (props) => {
  const {
    handleValue,
    handleBlur,
    selectedValue,
    values,
    options,
    errors,
    className,
    inputName,
    required,
    dark,
    isMulti,
    allowSpaces,
    renderExtra,
    idProp,
    helper,
  } = props;

  const [state, setState] = useState({
    inputValue: '',
    activeIndex: -1,
    valueIndex: -1,
    selectedIndex: -1,
    suggestions: options,
    shouldScroll: false,
  });

  const {
    inputValue,
    activeIndex,
    valueIndex,
    selectedIndex,
    suggestions,
    shouldScroll,
  } = state;
  const isFocused = activeIndex > -1;

  const inputRef = useRef(null);

  const handleValueChange = (action, i) => {
    const e = { target: { name: inputName, value: '' } };

    switch (action) {
      case 'add':
        if (!isMulti) {
          e.target.value = suggestions[i];
          setState({
            ...state,
            inputValue: '',
            activeIndex: -1,
            selectedIndex: options.indexOf(suggestions[i]),
          });
        } else {
          e.target.value = [...values, suggestions[i]];
          setState({
            ...state,
            inputValue: '',
            valueIndex: -1,
            shouldScroll: true,
          });
        }
        break;

      default:
        if (!isMulti) {
          setState({
            ...state,
            inputValue: '',
            selectedIndex: -1,
            shouldScroll: true,
          });
        } else {
          e.target.value = values.filter((_, index) => index !== i);
        }
    }

    handleValue(e);
  };

  const handleClick = () => {
    setState({
      ...state,
      activeIndex: isFocused ? -1 : 0,
      valueIndex: -1,
      shouldScroll: true,
    });
  };

  const handleValueKeyDown = (e, i) => {
    const { key } = e;
    if (key === 'Delete' || key === 'Backspace') {
      handleValueChange('remove', i);
    }
  };

  // close suggestions on blur
  const handleInputBlur = (e) => {
    const { name } = e.target;
    setState({ ...state, activeIndex: -1, valueIndex: -1 });
    handleBlur({ target: { name, value: values || selectedValue } });
  };

  // reset active index on change
  const handleChange = (e) => {
    if (!isMulti && selectedIndex > -1) return;
    const val = e.target.value;

    setState({
      ...state,
      inputValue: allowSpaces ? val : val.trim(),
      activeIndex: activeIndex > -1 ? activeIndex : 0,
    });
  };

  const handleHover = (i) => {
    setState({ ...state, activeIndex: i, shouldScroll: false });
  };

  // keep input focused on value or suggestion click
  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    const lastIndex = suggestions && suggestions.length - 1;
    const lastValueIndex = values && values.length - 1;

    switch (key) {
      // enter or tab to add value
      case 'Tab':
      case 'Enter':
        if (!isFocused || e.shiftKey) return;
        e.preventDefault();

        if (suggestions.length === 0) return;

        handleValueChange('add', activeIndex);
        break;

      // esc to close suggestions
      case 'Escape':
        setState({ ...state, activeIndex: -1, valueIndex: -1 });
        break;

      case 'Delete':
      case 'Backspace':
        // if single value, remove previously set value
        if (!isMulti && selectedIndex > -1) handleValueChange('remove');

        if (inputValue.length > 0) return;

        // backspace or del deletes focused value,
        // or last value if no value is focused
        if (valueIndex === -1) {
          handleValueChange('remove', lastValueIndex);
        } else {
          if (valueIndex === lastValueIndex) {
            setState({ ...state, valueIndex: -1 });
          }
          handleValueChange('remove', valueIndex);
        }
        break;

      // up arrow
      case 'ArrowUp': {
        e.preventDefault();

        const newIndex = activeIndex > 0 ? activeIndex - 1 : lastIndex;
        setState({
          ...state,
          activeIndex: newIndex,
          valueIndex: -1,
          shouldScroll: true,
        });
        break;
      }

      // down arrow
      case 'ArrowDown': {
        e.preventDefault();

        const newIndex = activeIndex < lastIndex ? activeIndex + 1 : 0;
        setState({
          ...state,
          activeIndex: newIndex,
          valueIndex: -1,
          shouldScroll: true,
        });
        break;
      }

      // left arrow to focus last value and close suggestions
      case 'ArrowLeft':
        if (!isMulti) return;
        if (values.length > 0 && inputValue.length === 0) {
          if (valueIndex === -1) {
            setState({ ...state, activeIndex: -1, valueIndex: lastValueIndex });
          } else if (valueIndex > 0) {
            setState({ ...state, valueIndex: valueIndex - 1 });
          }
        }
        break;

      // right arrow to focus next value if input is empty
      case 'ArrowRight':
        if (!isMulti) return;
        if (values.length > 0 && inputValue.length === 0) {
          if (valueIndex > -1 && valueIndex < lastValueIndex) {
            setState({ ...state, valueIndex: valueIndex + 1 });
          } else {
            setState({ ...state, valueIndex: -1 });
          }
        }
        break;

      default:
        if (!isMulti) return;
        setState({ ...state, valueIndex: -1 });
    }
  };

  const updateSuggestions = useCallback(() => {
    if (!isFocused) return;

    setState((prevState) => {
      if (selectedIndex > -1) {
        return {
          ...prevState,
          suggestions: options,
          activeIndex: selectedIndex,
        };
      }

      const query = inputValue.toLowerCase().trim();

      const newSuggestions = options
        .filter(
          (option) =>
            option.label.toLowerCase().indexOf(query) > -1 &&
            (isMulti ? !values.includes(option) : true)
        )
        .sort((a, b) => {
          const aIndex = a.label.toLowerCase().indexOf(query);
          const bIndex = b.label.toLowerCase().indexOf(query);

          if (aIndex === bIndex) return a.id - b.id;
          return aIndex - bIndex;
        });

      return {
        ...prevState,
        suggestions: newSuggestions,
        activeIndex: 0,
      };
    });
  }, [isFocused, selectedIndex, isMulti, values, inputValue, options]);

  useLayoutEffect(updateSuggestions, [updateSuggestions]);

  // restore previously selected index on remount
  useEffect(() => {
    if (!selectedValue) return;

    setState((prevState) => ({
      ...prevState,
      selectedIndex: options.indexOf(selectedValue),
    }));
  }, [options, selectedValue]);

  const wrapperClasses = [dark && styles.dark, styles.tags, className].join(
    ' '
  );

  return (
    <div className={errors && styles.invalid}>
      <TagList className={wrapperClasses}>
        {values &&
          values.map(({ label }, i) => (
            <EditableTag
              key={label}
              name={label}
              isFocused={i === valueIndex}
              onKeyDown={(e) => handleValueKeyDown(e, i)}
              onMouseDown={handleMouseDown}
              onClick={() => handleValueChange('remove', i)}
            />
          ))}

        <li className={styles.item}>
          {selectedValue && renderExtra(selectedValue.id, styles.extra)}
          <input
            type="text"
            name={inputName}
            value={selectedValue ? selectedValue.label : inputValue}
            className={styles['tag-input']}
            ref={inputRef}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleInputBlur}
            required={required}
            autoComplete="off"
          />
        </li>

        <Suggestions
          suggestions={suggestions}
          activeIndex={activeIndex}
          selectedIndex={selectedIndex}
          shouldScroll={shouldScroll}
          isOpen={isFocused}
          handleHover={handleHover}
          handleMouseDown={handleMouseDown}
          handleClick={handleValueChange}
          idProp={idProp}
          renderExtra={renderExtra}
        />
      </TagList>

      {helper &&
        (isValidElement(helper) ? (
          helper
        ) : (
          <span className="text-helper">{helper}</span>
        ))}

      <Errors error={errors} />
    </div>
  );
};

export default Select;
