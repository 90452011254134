////////////////////////
// Server flags
////////////////////////
const userFlags = Object.freeze({
  EMAIL_VERIFIED: 0,
  MFA_ENABLED: 1,
  STAFF: 2,
});

export function isEmailVerified(flags) {
  return !!(flags & userFlags.EMAIL_VERIFIED);
}

export function isMfaEnabled(flags) {
  return !!(flags & userFlags.MFA_ENABLED);
}

////////////////////////
// Server flags
////////////////////////
const serverFlags = Object.freeze({
  SPONSORED: 0,
});

export function isSponsored(flags) {
  return !!(flags & serverFlags.SPONSORED);
}
