import React, { isValidElement } from 'react';

import Icon from 'components/common/Icon/Icon';
import Errors from 'components/forms/Input/Errors';

import styles from './Input.scss';

const MonoInput = ({
  icon,
  type = 'text',
  onChange,
  name,
  placeholder,
  value,
  error,
  helper,
  disabled,
  className,
}) => {
  const containerClasses = [className, error && styles.invalid].join(' ');
  const wrapperClasses = [
    styles.wrapper,
    disabled && styles.disabled,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={containerClasses}>
      <div className={wrapperClasses}>
        {icon && <Icon icon={icon} className={styles.icon} />}

        <input
          type={type}
          className={styles.monoinput}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
        />
      </div>

      {helper &&
        (isValidElement(helper) ? (
          helper
        ) : (
          <span className="text-helper">{helper}</span>
        ))}

      {error && <Errors error={error} />}
    </div>
  );
};

export default MonoInput;
