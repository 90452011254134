import React, { useRef, useState, useEffect } from 'react';
import { Chart, CategoryScale, LinearScale, LineController, PointElement, LineElement } from 'chart.js';
import drawBackgroundPlugin from './plugins';

// Chart.js global defaults
Chart.defaults.defaultFontFamily =
  "'Source Sans Pro', Helvetica, 'Lucida Grande', Arial, sans-serif";
Chart.defaults.defaultFontSize = 14;
Chart.defaults.defaultFontColor = '#6d797a';

// Register scales and controllers
Chart.register(CategoryScale, LinearScale, LineController, PointElement, LineElement);
// Register plugins
Chart.register(drawBackgroundPlugin);

const options = {
  maintainAspectRatio: false,
  chartArea: { backgroundColor: '#282e36' },
  elements: {
    line: {
      borderWidth: 2,
      tension: 0.3,
    },
    point: {
      borderWidth: 0,
      hitRadius: 24,
      hoverRadius: 6,
      radius: 4,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: { color: '#13151e' },
      },
    ],
    yAxes: [
      {
        ticks: { autoSkipPadding: 40 },
        gridLines: { color: '#13151e' },
      },
    ],
  },
  tooltips: {
    backgroundColor: 'rgba(19, 21, 30, 0.9)',
    bodyAlign: 'center',
    bodyFontSize: 16,
    caretPadding: 10,
    caretSize: 12,
    cornerRadius: 8,
    displayColors: false,
    titleAlign: 'center',
    titleFontColor: '#6d787a',
    titleFontStyle: 'normal',
    titleMarginBottom: 12,
    xPadding: 24,
    yPadding: 16,
  },
};

const LineGraph = () => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartRef) {
      const newChart = new Chart(chartRef.current, {
        type: 'line',
        data: {
          labels: [
            'April 6',
            'April 7',
            'April 8',
            'April 9',
            'April 10',
            'April 11',
            'April 12',
          ],
          datasets: [
            {
              label: 'Votes',
              data: [415, 390, 370, 402, 355, 461, 413],
              backgroundColor: 'rgba(245, 131, 0, 0.25)',
              borderColor: '#f58300',
              pointBackgroundColor: '#f58300',
            },
          ],
        },
        options,
      });

      setChartInstance(newChart);
    }
  }, [chartRef]);

  return (
    <canvas
      ref={chartRef}
      aria-label="Votes for this server between April 6-April 12. April 6: 415, April 7: 390, April 8: 370, April 9: 402, April 10: 355, April 11: 461, April 12: 413"
    />
  );
};

export default LineGraph;
