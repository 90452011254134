import React from 'react';
import styles from './Icon.scss';

const Icon = ({ icon, className }) => {
  const IconComponent = icon;
  const classes = [styles.icon, className].join(' ');

  return <IconComponent className={classes} aria-hidden />;
};

export default Icon;
