const servers = [
  {
    id: 0,
    isSponsored: true,
    sponsoredUntil: 1747118713,
    countryCode: 'GB',
    name: 'My sponsored server',
    ip: 'play.mysponsoredserver.com',
    pingSuccess: true,
    description: window.btoa(
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra mi urna, in auctor urna efficitur non. Cras diam risus, tincidunt vehicula mi ultricies, mattis ullamcorper turpis. Maecenas luctus pulvinar eros a bibendum. Proin tortor quam, elementum non facilisis eget, laoreet et nisi. Donec eu consequat erat. Aliquam ipsum lorem, scelerisque id fringilla vel, maximus eu neque. Vivamus eu quam sed sapien convallis auctor. Cras dignissim iaculis ligula ut fringilla. Duis convallis sapien non dui tempus rhoncus. Nulla posuere bibendum ornare. Donec vel est vitae magna dapibus ornare.
      Ut maximus sapien eget ante ornare dictum. Integer porta non mi a pulvinar. Fusce vitae tincidunt est. Nullam aliquet condimentum luctus. Mauris libero lacus, aliquet nec ipsum quis, rutrum vulputate ex. Cras porta augue turpis, quis bibendum odio tempor non. Suspendisse molestie arcu sit amet aliquam tempus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In eu dolor justo.`
    ),
    version: '1.16',
    twitter: 'mcservers_gg',
    discord: 'mKPEXMh',
    website: 'https://pandiom.com',
    online: 283,
    max: 500,
    peak: 399,
    tags: 'pvp towny factions survival slimefun'.split(' '),
    lastPing: Date.now() - 59 * 1000,
    votes: 131732,
  },
  {
    id: 1,
    rank: 1,
    countryCode: 'US',
    name: 'Coolcraft',
    ip: 'coolio.com',
    pingSuccess: true,
    description: window.btoa(
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra mi urna, in auctor urna efficitur non. Cras diam risus, tincidunt vehicula mi ultricies, mattis ullamcorper turpis. Maecenas luctus pulvinar eros a bibendum. Proin tortor quam, elementum non facilisis eget, laoreet et nisi. Donec eu consequat erat. Aliquam ipsum lorem, scelerisque id fringilla vel, maximus eu neque. Vivamus eu quam sed sapien convallis auctor. Cras dignissim iaculis ligula ut fringilla. Duis convallis sapien non dui tempus rhoncus. Nulla posuere bibendum ornare. Donec vel est vitae magna dapibus ornare.
      Ut maximus sapien eget ante ornare dictum. Integer porta non mi a pulvinar. Fusce vitae tincidunt est. Nullam aliquet condimentum luctus. Mauris libero lacus, aliquet nec ipsum quis, rutrum vulputate ex. Cras porta augue turpis, quis bibendum odio tempor non. Suspendisse molestie arcu sit amet aliquam tempus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In eu dolor justo.`
    ),
    version: '1.15.2',
    twitter: 'mcservers_gg',
    discord: 'mKPEXMh',
    website: 'https://pandiom.com',
    online: 77,
    max: 128,
    peak: 115,
    tags: 'ftb creative survival modded'.split(' '),
    lastPing: 1590329714609,
    votes: 84372,
    owner: 0,
  },
  {
    id: 2,
    rank: 2,
    countryCode: 'US',
    name: 'Great server',
    ip: '127.0.0.1',
    pingSuccess: false,
    description: window.btoa(
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra mi urna, in auctor urna efficitur non. Cras diam risus, tincidunt vehicula mi ultricies, mattis ullamcorper turpis. Maecenas luctus pulvinar eros a bibendum. Proin tortor quam, elementum non facilisis eget, laoreet et nisi. Donec eu consequat erat. Aliquam ipsum lorem, scelerisque id fringilla vel, maximus eu neque. Vivamus eu quam sed sapien convallis auctor. Cras dignissim iaculis ligula ut fringilla. Duis convallis sapien non dui tempus rhoncus. Nulla posuere bibendum ornare. Donec vel est vitae magna dapibus ornare.
      Ut maximus sapien eget ante ornare dictum. Integer porta non mi a pulvinar. Fusce vitae tincidunt est. Nullam aliquet condimentum luctus. Mauris libero lacus, aliquet nec ipsum quis, rutrum vulputate ex. Cras porta augue turpis, quis bibendum odio tempor non. Suspendisse molestie arcu sit amet aliquam tempus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In eu dolor justo.`
    ),
    version: '1.15.2',
    twitter: 'mcservers_gg',
    discord: 'mKPEXMh',
    website: 'https://pandiom.com',
    online: 0,
    max: 128,
    peak: 0,
    tags: 'survival pvp factions hardcore'.split(' '),
    votes: 940,
  },
];

export default servers;
