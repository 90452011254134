import React from 'react';

import ToggleInput from 'components/forms/Input/ToggleInput';
import CheckboxInput from 'components/forms/Input/CheckboxInput';
import TextInput from 'components/forms/Input/TextInput';

import styles from '../AddServer.scss';

const Votifier = ({
  currentStep,
  stepNumber,
  values,
  errors,
  handleChange,
  handleBlur,
}) => {
  if (currentStep !== stepNumber) return null;

  return (
    <>
      <div className={styles.container}>
        <label className="text-accent" htmlFor="votifierEnabled">
          Votifier integration
        </label>
        <ToggleInput
          name="votifierEnabled"
          onChange={handleChange}
          checked={values.votifierEnabled}
        />

        {values.votifierEnabled && (
          <>
            <label className={styles.label} htmlFor="votifierIp">
              Voting IP
            </label>
            <div>
              <CheckboxInput
                label="Same as server IP"
                name="votifierSameIp"
                onChange={handleChange}
                checked={values.votifierSameIp}
              />

              <TextInput
                name={values.votifierSameIp ? 'ip' : 'votifierIp'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.votifierSameIp ? values.ip : values.votifierIp}
                disabled={values.votifierSameIp}
                errors={values.votifierSameIp ? errors.ip : errors.votifierIp}
              />
            </div>

            <label className={styles.label} htmlFor="votifierPort">
              Port
            </label>
            <TextInput
              name="votifierPort"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.votifierPort}
              errors={errors.votifierPort}
            />

            <label className={styles.label} htmlFor="votifierKey">
              Public key
            </label>
            <TextInput
              name="votifierKey"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.votifierKey}
              errors={errors.votifierKey}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Votifier;
