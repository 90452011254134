import React, { Fragment } from 'react';

import Flag from 'components/common/Flag';
import TextInput from 'components/forms/Input/TextInput';
import FileInput from 'components/forms/Input/FileInput';
import TextArea from 'components/forms/Input/TextArea';
import Select from 'components/forms/Input/Select';

import LinkIcon from 'assets/icons/link.svg';
import DiscordIcon from 'assets/social/Discord.svg';
import TwitterIcon from 'assets/social/Twitter.svg';
import InstagramIcon from 'assets/social/Instagram.svg';

import styles from '../AddServer.scss';

const links = [
  { name: 'website', label: 'Website', icon: LinkIcon, template: 'https://' },
  {
    name: 'discord',
    label: 'Discord',
    icon: DiscordIcon,
    template: 'discord.gg/',
  },
  {
    name: 'twitter',
    label: 'Twitter',
    icon: TwitterIcon,
    template: 'twitter.com/',
  },
  {
    name: 'instagram',
    label: 'Instagram',
    icon: InstagramIcon,
    template: 'instagram.com/',
  },
];

const General = ({
  currentStep,
  stepNumber,
  values,
  errors,
  restrictions,
  tags,
  countries,
  handleChange,
  handleBlur,
}) => {
  if (currentStep !== stepNumber) return null;

  return (
    <>
      <div className={styles.container}>
        <label htmlFor="name" className={styles.label}>
          Name
        </label>
        <TextInput
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          errors={errors.name}
          helper="2-40 characters"
        />

        <label htmlFor="banner" className={styles.label}>
          Upload a banner
        </label>
        <FileInput
          name="banner"
          preview={values.banner}
          errors={errors.banner}
          restrictions={restrictions}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <label htmlFor="hostname" className={styles.label}>
          Hostname
        </label>
        <TextInput
          name="ip"
          errors={errors.ip}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.ip}
        />

        <div htmlFor="port" className={styles.label}>
          <p>
            Port <span className="text-muted">(optional)</span>
          </p>
        </div>
        <TextInput
          name="port"
          errors={errors.port}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.port}
        />

        <label htmlFor="description" className={styles.label}>
          Description
        </label>
        <TextArea
          rows="4"
          name="description"
          minLength={50}
          maxLength={4000}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          errors={errors.description}
        />

        <label htmlFor="tagInput" className={styles.label}>
          Tags
        </label>
        <Select
          inputName="tags"
          values={values.tags}
          errors={errors.tags}
          options={tags}
          handleValue={handleChange}
          handleBlur={handleBlur}
          helper="Minimum 2 tags"
          isMulti
        />

        <label htmlFor="country" className={styles.label}>
          Country
        </label>
        <Select
          inputName="country"
          options={countries}
          name="country"
          handleValue={handleChange}
          handleBlur={handleBlur}
          selectedValue={values.country}
          errors={errors.country}
          idProp="countryCode"
          renderExtra={(id, classes) => (
            <Flag
              countryCode={id}
              className={classes}
              style={{
                display: 'unset',
                width: 'unset',
                height: 'unset',
                verticalAlign: 'unset',
                fontSize: '1.5em',
              }}
            />
          )}
          allowSpaces
        />

        <div className={styles.label}>
          <p>
            Links <span className="text-muted">(optional)</span>
          </p>
        </div>
        <div className={styles.links}>
          {links.map(({ icon, name, label, template }) => (
            <Fragment key={name}>
              <label className={styles['link-label']} htmlFor={name}>
                {label}
              </label>
              <TextInput
                icon={icon}
                extra={template}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                errors={errors[name]}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default General;
