import React from 'react';

import Icon from 'components/common/Icon/Icon';
import ClipboardCopyIcon from 'assets/icons/clipboard-copy.svg';
import Copyable from 'components/common/Copyable/Copyable';

import styles from './Recaps.scss';

const PrimaryInfo = ({ version, ip }) => {
  return (
    <>
      <p>Version</p>
      <p className="text-emphasis">{version}</p>

      <hr />
      <p>Server IP</p>

      {ip && (
        <Copyable
          extra={<Icon icon={ClipboardCopyIcon} />}
          className={styles.copy}
          text={ip}
        />
      )}
    </>
  );
};

export default PrimaryInfo;
