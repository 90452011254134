import React from 'react';

import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import Button from 'components/common/Button/Button';
import RadioInput from 'components/forms/Input/RadioInput';

import UserInformation from './UserInformation';
import Security from './Security';

import { getProfile } from 'utils/Data';

import styles from './Account.scss';
import Spinner from 'components/common/Loading/Spinner';

export default class Account extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      loaded: false,

      // Data
      user: {},
      selectedOption: '',
    };
  }

  componentDidMount() {
    getProfile().then(profile => {
      this.setState({
        loaded: true,

        user: profile.profile,
      });
    });
  }

  render() {
    const { loaded, user, selectedOption } = this.state;

    if (!loaded) {
      return <Spinner />;
    }

    return (
      <>
        <UserInformation user={user} />

        <Security user={user} />

        <div className={styles.data}>
          <SectionTitle text="Manage your data" />

          <form className={styles.container} method="POST" onSubmit={this.handleSubmit}>
            <div>
              <RadioInput
                label="Download personal data"
                name="data"
                value="Download"
                checked={selectedOption === 'Download'}
                onChange={() => setInfo({ ...info, selectedOption: 'Download' })}
              />
              <RadioInput
                label="Delete account"
                name="data"
                value="Delete"
                checked={selectedOption === 'Delete'}
                onChange={() => setInfo({ ...info, selectedOption: 'Delete' })}
              />
            </div>

            <div className={styles['center-group']}>
              <Button
                className={styles.button}
                color={selectedOption === 'Delete' ? 'delete' : 'hollow'}
              >
                {selectedOption === 'Download' ? 'Send request' : 'Continue'}
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
