import Constants from './Constants';

export function isSet(obj) {
  return obj !== undefined && obj !== null;
}

export function isObjectEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}

export function isLoggedIn() {
  return (
    isSet(localStorage.getItem('token')) && isSet(localStorage.getItem('user'))
  );
}

export function isDevMode() {
  return Constants.ENVIRONMENT === 'development';
}
