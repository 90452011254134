import React from 'react';

import ServerRow from 'components/servers/ServerRow/ServerRow';
import Spinner from 'components/common/Loading/Spinner';
import TextInput from 'components/forms/Input/TextInput';
import API from 'api/API';
import SearchIcon from 'assets/icons/search.svg';
import TagIcon from 'assets/icons/tag.svg';
import FlagIcon from 'assets/icons/flag.svg';

import styles from './Home.scss';

class Home extends React.Component {
  constructor(props) {
    super(props);

    const type = props?.extra?.type ?? 'most-popular';

    this.state = {
      loading: true,

      page: 0,
      type,

      data: [],
    };
  }

  componentDidMount() {
    const { type } = this.state;

    if (type === 'most-popular') {
      API.getData('/servers', this);
    } else if (type === 'recently-bumped') {
      API.getData('/servers/recently-bumped', this);
    }
  }

  render() {
    const { loading, page, data } = this.state;

    let rank = (page * 25) + 1;

    if (loading || !data) {
      return <Spinner />;
    }

    return (
      <div className="content">
        <form method="POST" action="" className={styles.form}>
          <TextInput
            className={styles.search}
            icon={SearchIcon}
            name="search"
            placeholder="Search"
            aria-label="Search"
            dark
            noValidation
          />
          <TextInput
            icon={TagIcon}
            name="tags"
            placeholder="Tags"
            aria-label="Tags"
            dark
            noValidation
          />
          <TextInput
            icon={FlagIcon}
            name="country"
            placeholder="Country"
            aria-label="Country"
            dark
            noValidation
          />
        </form>

        <ul className={styles.list}>
          {data.map((s) => (
            <ServerRow
              className={styles.row}
              key={`server-${s.id}`}
              rank={rank++}
              data={s}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default Home;
