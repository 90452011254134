import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

export default class Spinner extends React.Component {

  render() {
    return (
      <SpinnerCircularFixed
        color="#f58300"
        size={100}
        style={{ margin: 'auto' }}
      />
    );
  }
}