import React, { useRef, useLayoutEffect } from 'react';
import styles from './Input.scss';

const Suggestions = (props) => {
  const listRef = useRef(null);

  const {
    suggestions,
    activeIndex,
    selectedIndex,
    shouldScroll,
    isOpen,
    handleHover,
    handleMouseDown,
    handleClick,
    renderExtra,
  } = props;

  // prevent body scroll when scrolling in suggestions
  const handleScroll = (e) => {
    e.preventDefault();
  };

  // scroll when using arrows
  useLayoutEffect(() => {
    const optionEl = listRef.current
      ? listRef.current.childNodes[activeIndex]
      : null;

    if (shouldScroll && optionEl) {
      const listEl = optionEl.parentNode;
      const listHeight = listEl.clientHeight;
      const optionHeight = optionEl.offsetHeight;
      const optionTop = optionEl.offsetTop;
      const listOptionDistance = listEl.scrollTop + listHeight - optionHeight;

      if (listEl.scrollTop > optionTop || activeIndex === selectedIndex) {
        listEl.scrollTop = optionTop;
        return;
      }

      if (listOptionDistance < optionTop) {
        listEl.scrollTop = optionTop - listHeight + optionHeight;
      }
    }
  }, [shouldScroll, selectedIndex, activeIndex]);

  const classes = [styles.suggestions, isOpen && styles['is-focused']]
    .filter(Boolean)
    .join(' ');

  return (
    <ul className={classes} onScroll={handleScroll} ref={listRef}>
      {suggestions.length === 0 ? (
        <li>
          <button type="button" className={styles.suggestion} disabled>
            No options
          </button>
        </li>
      ) : (
        suggestions.map(({ id, label }, i) => (
          <li key={label}>
            <button
              className={`${[
                styles.suggestion,
                activeIndex === i && styles['is-active'],
              ].join(' ')}`}
              type="button"
              onMouseOver={() => handleHover(i)}
              onFocus={() => handleHover(i)}
              onMouseDown={handleMouseDown}
              onClick={() => handleClick('add', i)}
            >
              {renderExtra && renderExtra(id, styles.extra)}
              {label}
            </button>
          </li>
        ))
      )}
    </ul>
  );
};

export default Suggestions;
