import React, { useState } from 'react';

import Button from 'components/common/Button/Button';
import LoadingButton from 'components/common/Button/LoadingButton';

import pwnedPasswordCount from 'utils/PwnedPassword';

import styles from '../../pages/Authentication/Authentication.scss';

export function PromptButtons(props) {
  const { submitting, continueAnyway, goBack } = props;

  if (submitting) {
    return <LoadingButton />;
  }

  return (
    <div className={styles.groupedButtons}>
      <Button type="button" onClick={goBack} className={styles.btnGroup}>
        Go Back
      </Button>

      <Button
        type="button"
        onClick={continueAnyway}
        color="delete"
        className={styles.btnGroup}
      >
        Continue Anyway
      </Button>
    </div>
  );
}

export function PwnedPasswordPrompt(props) {
  const { count, page, submission } = props;

  const [submitting, setSubmitting] = useState(false);

  function handleContinue() {
    setSubmitting(true);

    submission();
  }

  function handleGoBack(e) {
    e.preventDefault();

    page.setState({ pwnedState: 2 });
  }

  return (
    <div className={styles.wrapper}>
      <h2>Security Warning</h2>
      <h3>Unsafe Password</h3>

      <div className={styles.group}>
        <h3>What does this mean?</h3>
        <p>
          Your password is well known and commonly used. It is known to have
          been used <b>at least {count} times</b> in data breaches. Please
          consider using a different, more secure password.{' '}
          <b>
            Click &quot;Continue&quot; and you can change your password or if
            you want to continue with your insecure one.
          </b>
        </p>
      </div>

      <div className={styles.group}>
        <h3>How do you know this?</h3>
        <p>
          We use a very reputable service called{' '}
          <a
            href="https://haveibeenpwned.com/"
            rel="noreferrer"
            className="link"
            target="_blank"
          >
            HaveIBeenPwned
          </a>{' '}
          to check if your password has been in previous breaches. This is done
          client-side and with partial-hash matching. We nor HaveIBeenPwned know
          your password.
        </p>
      </div>

      <div className={styles.group}>
        <h3>What should I do?</h3>
        <p>
          We <b>highly recommend</b> using a password manager and generating a
          unique and random password for each site you use. If you do not want
          or cannot use a password manager then thinking of 3 random words is
          another way to create a more secure password.
        </p>
      </div>

      <PromptButtons
        submitting={submitting}
        continueAnyway={handleContinue}
        goBack={handleGoBack}
      />
    </div>
  );
}

export const checkPasswordUnsafe = async (value) => {
  const count = await pwnedPasswordCount(value);

  if (count > 0) {
    return {
      count,
      error: (
        <>
          This password has been seen
          {count === 1 ? (
            ' in a data breach '
          ) : (
            <>
              <strong> {count}</strong> times in data breaches
            </>
          )}{' '}
          and is <strong>not considered secure</strong>. Please choose a
          different password, or click the &quot;Create account&quot; button
          again to register with an
          <strong> unsafe password</strong>.{' '}
          <a
            href="https://www.troyhunt.com/ive-just-launched-pwned-passwords-version-2/"
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about compromised passwords.
          </a>
        </>
      ),
    };
  }

  return {};
};
