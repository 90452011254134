import React from 'react';
import styles from './Input.scss';

const CheckboxInput = ({ name, value, checked, onChange, label }) => {
  return (
    <label className={styles.check}>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.box} />
      {label}
    </label>
  );
};

export default CheckboxInput;
