import React from 'react';

import Header from 'components/common/Header/Header';
import { NavLink, Routes, Route } from 'react-router-dom';

import Account from './Account/Account';
import Server from './Server/Server';
import Billing from './Billing/Billing';
import AddServer from './AddServer/AddServer';

import styles from './Dashboard.scss';

const pages = [
  { path: '', label: 'Account', main: <Account />, tabs: true },
  { path: '/server', label: 'Server', main: <Server />, tabs: true },
  { path: '/billing', label: 'Billing', main: <Billing />, tabs: true },
  { path: '/server/add', label: 'Add Server', main: <AddServer /> },
];

// This is a hacky workaround for marking a tab as active. With react-router-dom v6 they changed the pathing logic
// which meant that for Account to be active it needs the path to end with a trailing slash.
// That's ugly and nothing else is like that so we will do this hack for now.
// see #21 for more info.
function isActive(page, nav) {
  if (page.label === 'Account' && window.location.pathname === '/dashboard') {
    return true;
  }
  return page.label !== 'Account' && nav.isActive;
}

function Dashboard() {
  return (
    <div className="content-columns">
      <Routes>
        {pages.map((page) => (
          <Route
            key={`/dashboard${page.path}`}
            path={page.path}
            element={
              page.tabs ? (
                <>
                  <Header text="Dashboard" />

                  <div className={styles.tabs}>
                    {pages
                      .filter((p) => p.tabs)
                      .map((p) => (
                        <NavLink
                          key={p.label}
                          className={(nav) =>
                            isActive(p, nav)
                              ? `${styles.tab} ${styles.active}`
                              : styles.tab
                          }
                          to={`/dashboard${p.path}`}
                        >
                          {p.label}
                        </NavLink>
                      ))}
                  </div>

                  {page.main}
                </>
              ) : (
                page.main
              )
            }
          />
        ))}
      </Routes>
    </div>
  );
}

export default Dashboard;
