import React from 'react';

import Flag from 'components/common/Flag';
import ServerBanner from 'components/servers/ServerBanner/ServerBanner';
import TagList from 'components/servers/TagList/TagList';
import Tag from 'components/servers/Tag/Tag';
import Icon from 'components/common/Icon/Icon';

import PencilIcon from 'assets/icons/pencil.svg';

import styles from '../AddServer.scss';
import ServerInfo from 'components/servers/ServerInfo/ServerInfo';

const Review = ({ currentStep, stepNumber, values, goToStep }) => {
  const {
    name: serverName,
    banner,
    ip,
    port,
    description,
    tags,
    country: { id: countryCode, label: countryName },
    website,
    discord,
    twitter,
    instagram,
    votifierEnabled,
    votifierSameIp,
    votifierIp,
    votifierPort,
    /* webhooksEnabled,
    webhooksUrl,
    events, */
  } = values;

  const links = [
    { label: 'Website', value: website, template: '' },
    { label: 'Discord', value: discord, template: 'discord.gg/' },
    { label: 'Twitter', value: twitter, template: 'twitter.com/' },
    { label: 'Instagram', value: instagram, template: 'instagram.com/' },
  ];

  const handleStepChange = (e, i) => {
    const { key } = e;
    if (key === 'Enter' || key === ' ') goToStep(i);
  };

  // edit button to jump to appropriate step
  const EditButton = ({ step }) => {
    return (
      <span
        className="action link"
        role="button"
        tabIndex="0"
        onClick={() => goToStep(step)}
        onKeyDown={(e) => handleStepChange(e, step)}
      >
        <Icon icon={PencilIcon} />
        Edit
      </span>
    );
  };

  if (currentStep !== stepNumber) return null;

  return (
    <ServerInfo className={styles.serverInfo} hideChart={true} data={{
      id: 0,
      name: serverName,
      description: description,
      countryCode: countryCode,
      banner: "e426ea9b4865c509086ff3759b0df835121f507cff9e12ad586b16ff582de361.png", // TODO: Add banner
      ip: ip + (port && port !== 25565 ? `:${port}` : ''),
      flags: 0,
      votes: 0,
      status: "offline",
      version: "1.0.0",
      playersOnline: 0,
      playersMax: 0,
      lastPing: 0,
      tags: tags.map(tag => tag.label),
      website,
      discord,
      twitter,
      instagram,
    }} />
  );
};

export default Review;
