import { useEffect, useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { getCountryName } from 'data/countries';

// mock data
import servers from 'data/servers';

/*
 * This hook fetches a server object for a given server ID
 */
const useServerInfo = (id) => {
  const [data, setData] = useState(null);

  const getLastPing = (lastPing) => {
    if (!+lastPing) return null;
    return formatDistanceToNowStrict(lastPing);
  };

  useEffect(() => {
    const result = servers.find((server) => server.id === Number(id));

    if (result) {
      const description = window.atob(result.description);
      const countryName = getCountryName(result.countryCode);
      const timeSinceLastPing = getLastPing(result.lastPing);

      setData({ ...result, description, countryName, timeSinceLastPing });
    }
  }, [id]);

  return data;
};

export default useServerInfo;
