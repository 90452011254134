import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import Constants from './utils/Constants';
import App from './App';

Sentry.init({
  dsn: env.SENTRY_DSN,
  integrations: [new BrowserTracing()],

  tracesSampleRate: env.ENVIRONMENT === 'production' ? 0.1 : 1.0,

  release: Constants.COMMIT,
  environment: Constants.BRANCH,
  initialScope: {
    tags: { commit: Constants.COMMIT },
  },
});

const container = document.getElementById('App');
const root = createRoot(container);
root.render(<App />);
