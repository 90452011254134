import React from 'react';
import { Link } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import API from 'api/API';
import Button from 'components/common/Button/Button';
import LoadingButton from 'components/common/Button/LoadingButton';
import {
  checkPasswordUnsafe,
  PwnedPasswordPrompt,
} from 'components/forms/PwnedPasswordPrompt';
import TextInput from 'components/forms/Input/TextInput';
import Errors from 'components/forms/Input/Errors';
import Icon from 'components/common/Icon/Icon';
import { isDevMode } from 'utils/Utils';
import FormValidation from 'validation/FormValidation';
import { signupValidation } from 'validation/Config';

import UserIcon from 'assets/icons/user.svg';
import MailIcon from 'assets/icons/mail.svg';
import KeyIcon from 'assets/icons/key.svg';

import styles from './Authentication.scss';

function SignedUp(props) {
  const { email } = props;

  return (
    <div className={styles.submitted}>
      <h3>Thank you for signing up!</h3>

      <Icon icon={MailIcon} className={styles.icon} />

      <p>
        We have sent you a confirmation link to{' '}
        <span className="text-accent">{email}</span>. Check your inbox and
        confirm your email address to start using your account!
      </p>

      <p className="text-helper">
        It may take a few minutes for the email to send. If you haven&apos;t
        received it, make sure to check your spam folder.
      </p>
    </div>
  );
}

export default class Signup extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      // Fields
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      captcha: '',
      errors: {},
      // Pwned passwords - state:
      // 0 = not checked, 1 = checked
      // 2 = seen prompt, 3 = continue anyway
      pwnedState: 0,
      pwnedCount: 0,
      // API
      loading: false,
      success: false,
    };

    this.validation = new FormValidation(this, signupValidation);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  async handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const { username, email, password, confirmPassword, captcha, pwnedState } =
      this.state;

    if (pwnedState === 0) {
      const unsafePassword = await checkPasswordUnsafe(password);

      if (unsafePassword.error) {
        this.setState({
          pwnedState: 1,
          pwnedCount: unsafePassword.count,
        });
      } else {
        this.setState({ pwnedState: 1 });
      }
      return;
    }

    // Do API request
    API.post(
      '/auth/register',
      { username, email, password, confirmPassword, captcha },
      this
    ).then((json) => {
      if (json.code || json.error) {
        this.showError(json.error);
      } else {
        this.setState({ success: true });

        localStorage.setItem('user', JSON.stringify(json.user));
        localStorage.setItem('token', json.token);
      }
    });
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
    this.validation.validate(this.state);
  }

  handleBlur() {
    this.validation.validate(this.state);
  }

  // hCaptcha
  handleCaptchaComplete(token) {
    this.setState({ captcha: token });
  }

  handleCaptchaError(e) {
    this.showError(JSON.stringify(e));
  }

  // This shows a "general" error. This appears at the bottom of the form
  showError(error) {
    this.setState({ errors: { general: error } });
  }

  render() {
    const {
      success,
      username,
      email,
      password,
      confirmPassword,
      loading,
      errors,
      pwnedState,
      pwnedCount,
    } = this.state;

    if (success) {
      return <SignedUp email={email} />;
    }

    if (pwnedState === 1 && pwnedCount > 0) {
      return (
        <PwnedPasswordPrompt
          count={pwnedCount}
          page={this}
          submission={this.handleSubmit}
        />
      );
    }

    return (
      <form
        method="POST"
        className={styles.wrapper}
        onSubmit={this.handleSubmit}
      >
        <h3>Create an account</h3>

        <div className={styles.group}>
          <label htmlFor="username">Username</label>
          <TextInput
            icon={UserIcon}
            type="text"
            name="username"
            value={username}
            autoComplete="username"
            errors={errors.username}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            helper="Minimum 2 characters"
          />
        </div>

        <div className={styles.group}>
          <label htmlFor="email">Email address</label>
          <TextInput
            icon={MailIcon}
            type="email"
            name="email"
            value={email}
            autoComplete="email"
            errors={errors.email}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </div>

        <div className={styles.group}>
          <label htmlFor="password">Password</label>
          <TextInput
            icon={KeyIcon}
            type="password"
            name="password"
            value={password}
            autoComplete="new-password"
            errors={errors.password}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            helper="Minimum 8 characters"
          />
        </div>

        <div className={styles.group}>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <TextInput
            icon={KeyIcon}
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            autoComplete="new-password"
            errors={errors.confirmPassword}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </div>

        {!isDevMode() && (
          <div className={styles.group}>
            <HCaptcha
              sitekey="28eca205-77eb-491c-932f-b09f0d3c609b"
              theme="dark"
              reCaptchaCompat="false"
              onError={(event) => this.handleCaptchaError(event)}
              onVerify={(token) => this.handleCaptchaComplete(token)}
            />
          </div>
        )}

        {errors.general && <Errors error={errors.general} />}

        <div className={styles.group}>
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              type="submit"
              disabled={!this.validation.isSubmittable(this.state)}
            >
              Create account
            </Button>
          )}
        </div>

        <p className="text-helper">
          By creating an account, you agree to our{' '}
          <Link to="/terms" className="link">
            Terms of service
          </Link>{' '}
          and{' '}
          <Link to="/privacy" className="link">
            Privacy policy
          </Link>
          .
        </p>
      </form>
    );
  }
}
