import { isDevMode } from 'utils/Utils';

/*
 * Common
 */
const username = {
  required: true,
  minLength: {
    value: 2,
    message: 'Username needs to have at least 2 characters',
  },
  maxLength: {
    value: 30,
    message: 'Username cannot be longer than 30 characters',
  },
  pattern: {
    value: /[a-zA-Z0-9_]+/,
    message: 'Enter a valid username. Allowed characters: a-z, A-Z, 0-9 and _',
  },
};

const email = {
  required: true,
  pattern: {
    value: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,24}/i,
    message: 'Enter a valid email address',
  },
};

const password = {
  required: true,
  trim: false,
  minLength: {
    value: 8,
    message: 'Password needs to have at least 8 characters',
  },
  maxLength: {
    value: 200,
    message: 'Password cannot be longer than 200 characters',
  },
};

/*
 * SignUp
 */
export const signupValidation = {
  username,
  email,
  password,
  confirmPassword: {
    required: true,
    trim: false,
    matchesField: {
      value: 'password',
      message: 'Passwords need to match',
    },
  },
  captcha: {
    required: !isDevMode(),
  },
};

/*
 * SignIn
 */
export const signinValidation = {
  email,
  password,
};

/*
 * Profile
 */
export const userInformationValidation = {
  username,
  email,
};

export const securityValidation = {
  password,
  newPassword: password,
  confirmPassword: {
    required: true,
    trim: false,
    matchesField: {
      value: 'newPassword',
      message: 'Passwords need to match',
    },
  },
};
