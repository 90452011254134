import React from 'react';

import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import TextInput from 'components/forms/Input/TextInput';
import Button from 'components/common/Button/Button';

import API from 'api/API';
import FormValidation from 'validation/FormValidation';
import { userInformationValidation } from 'validation/Config';

import styles from './Account.scss';
import LoadingButton from 'components/common/Button/LoadingButton';

export default class UserInformation extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      loading: false,

      // Fields
      username: props.user.username,
      email: props.user.email,

      user: props.user,
      errors: {},
    };

    this.validation = new FormValidation(this, userInformationValidation);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
    this.validation.validate(this.state);
  }

  handleBlur() {
    this.validation.validate(this.state);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { user, username, email } = this.state;

    // Do API request
    API.put('/profile', { username, email }, this).then(() => {
      user.username = username;
      user.email = email;
      localStorage.setItem('profile', JSON.stringify(user));
    });
  }

  render() {
    const { loading, errors, username, email } = this.state;

    return (
      <div className={styles.section}>
        <SectionTitle text="User information" />

        <form className={styles.info} method="POST" onSubmit={this.handleSubmit}>
          <div className={styles['input-group']}>
            <label className={styles.label} htmlFor="username">
              Username
            </label>
            <TextInput
              name="username"
              className={styles.name}
              errors={errors.username}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={username}
            />
          </div>

          <div className={styles['input-group']}>
            <label className={styles.label} htmlFor="email">
              Email
            </label>
            <TextInput
              name="email"
              className={styles.email}
              errors={errors.email}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={email}
            />
          </div>

          <div className={styles['input-group']}>
            { loading ? 
                <LoadingButton className={styles.button} children='Saving...' />
              : <Button
                  className={styles.button}
                  color="hollow"
                  disabled={!this.validation.isSubmittable(this.state)}
                >
                  Save changes
                </Button>
            }
          </div>
        </form>
      </div>
    );
  }
}
